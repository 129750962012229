<template>
  <div class="card card-custom card-stretch gutter-b">
    <div class="card-header border-0 pt-5">
      <h3 class="card-title font-weight-bolder text-dark">Rezervasyonlar</h3>
    </div>
    <!--begin::Body-->
    <div class="card-body pt-2 pb-0 mt-n3">
      <div class="table-responsive">
        <table class="table table-borderless table-vertical-center">
          <thead>
            <tr>
              <th class="p-0 min-w-150px"></th>
              <th class="p-0 min-w-100px"></th>
              <th class="p-0 min-w-100px"></th>
              <th class="p-0 min-w-40px"></th>
            </tr>
          </thead>
          <tbody>
            <BookingItem
              v-for="booking in bookings"
              :key="booking.reservationNo"
              :reservation-id="booking.id"
              :booked-by="booking.bookedBy"
              :phone="booking.phone"
              :reservation-no="booking.reservationNo"
              :participants="booking.participants"
              :price="booking.price"
              :saved-participant-count="booking.savedParticipantCount"
            ></BookingItem>
          </tbody>
        </table>
      </div>
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
import BookingItem from "@/view/pages/operation/components/details/bookings/BookingItem";
export default {
  props: {
    bookings: Array
  },
  components: {
    BookingItem
  },
}
</script>

<style>
</style>