<template>
  <div class="card card-custom bg-light card-stretch gutter-b">
    <div class="card-header border-0 pt-5">
      <h3 class="card-title font-weight-bolder text-dark">Kotasyon</h3>
      <div class="card-toolbar">
        <button
          class="btn btn-sm btn-light font-weight-bolder font-size-sm"
          @click="$bvModal.show('modal-add')"
        >
          Kalem Ekle
        </button>
      </div>
    </div>
    <!--begin::Body-->
    <div class="card-body pt-2 pb-0 mt-n3">
      <QuotationList
        :expenses="expenses"
        :incomes="incomes"
        @change="fetchQuotation"
      />
    </div>
    <!--end::Body-->
    <b-modal
      id="modal-add"
      ref="modal-add"
      no-fade
      centered
      scrollable
      header-class="py-3"
      footer-class="py-3"
      @cancel="handleModalCancel"
      @hide="handleModalCancel"
      @ok="handleModalOk"
    >
      <template #modal-header="{ cancel }">
        <button
          type="button"
          class="btn btn-icon btn-hover-light btn-sm"
          @click="cancel"
        >
          <i class="ki ki-close icon-nm text-dark"></i>
        </button>
        <div
          class="d-flex flex-fill align-items-center justify-content-center pt-2"
        >
          <div
            class="d-flex flex-column align-items-center"
            style="margin-left: -32px;"
          >
            <label>{{ modalStep }}</label>
            <h5>{{ modalTitle }}</h5>
          </div>
        </div>
      </template>

      <div class="max-h-300px">
        <section v-if="modal.activeStep === 1">
          <div class="form-group">
            <div
              class="option selectable mb-3"
              :class="{ selected: modal.itemType === 'INCOME' }"
              @click="modal.itemType = 'INCOME'"
            >
              <span class="option-label">
                <span class="option-head">
                  <div class="option-title">
                    Gelir ekle
                  </div>
                </span>
              </span>
            </div>
          </div>
          <div class="form-group">
            <div
              class="option selectable mb-3"
              :class="{ selected: modal.itemType === 'EXPENSE' }"
              @click="modal.itemType = 'EXPENSE'"
            >
              <span class="option-label">
                <span class="option-head">
                  <div class="option-title">
                    Masraf ekle
                  </div>
                </span>
              </span>
            </div>
          </div>
        </section>
        <section v-if="modal.activeStep === 2">
          <div
            v-for="(item, index) in modalItems"
            :key="index"
            class="form-group"
          >
            <div
              class="option selectable mb-3"
              :class="{ selected: modal.itemId === item.id }"
              @click="modal.itemId = item.id"
            >
              <span class="option-label">
                <span class="option-head">
                  <div class="option-title">
                    {{ item.name }}
                  </div>
                </span>
              </span>
            </div>
          </div>
        </section>
        <section v-if="modal.activeStep === 3">
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">{{
              selectedItemsName
            }}</label>
            <input
              type="number"
              class="form-control form-control-lg"
              :placeholder="`${selectedItemsName} girin`"
              v-model="modal.amount"
            />
          </div>
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1"
              >Açıklama (İsteğe bağlı)</label
            >
            <input
              type="text"
              class="form-control form-control-lg"
              placeholder="Açıklama girin"
              v-model="modal.description"
            />
          </div>
        </section>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div
          class="d-flex flex-row align-items-center justify-content-between flex-fill"
        >
          <button
            v-if="modal.activeStep === 1"
            type="button"
            class="btn btn-hover-transparent-dark font-weight-bolder py-4"
            @click="cancel"
          >
            İptal et
          </button>
          <button
            v-if="modal.activeStep > 1"
            type="button"
            class="btn btn-hover-transparent-dark font-weight-bolder py-4"
            @click="modal.activeStep -= 1"
          >
            <i class="ki ki-arrow-back"></i>Geri
          </button>

          <button
            v-if="modal.activeStep === modal.steps.length"
            type="button"
            class="btn btn-dark font-weight-bolder px-5 py-4"
            :disabled="isModalSaveDisabled"
            @click="ok"
          >
            Kaydet
          </button>
          <button
            v-if="modal.activeStep < modal.steps.length"
            type="button"
            class="btn btn-dark font-weight-bolder px-5 py-4"
            :disabled="isModalSaveDisabled"
            @click="modal.activeStep += 1"
          >
            Sonraki
            <i class="ki ki-arrow-next"></i>
          </button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import QuotationList from "@/view/pages/operation/components/details/quotation/QuotationList.vue";

export default {
  components: {
    QuotationList
  },
  data() {
    return {
      modal: {
        activeStep: 1,
        steps: [
          {
            number: 1,
            title: "Ne eklemek istiyorsunuz?"
          },
          {
            number: 2,
            title: "Bir kalem seçin"
          },
          {
            number: 3,
            title: "Tutar girin"
          }
        ],
        expenses: [],
        incomes: [],
        itemType: "",
        itemId: 0,
        amount: 0,
        description: ""
      },
      incomes: [],
      expenses: []
    };
  },
  watch: {
    "modal.itemType"() {
      this.modal.itemId = 0;
    }
  },
  computed: {
    pnr() {
      return this.$route.params.pnr;
    },
    modalStep() {
      return `${this.modal.activeStep} ADIM/${this.modal.steps.length}`;
    },
    modalTitle() {
      return this.modal.steps[this.modal.activeStep - 1].title;
    },
    isModalSaveDisabled() {
      let state;
      switch (this.modal.activeStep) {
        case 1:
          state =
            this.modal.itemType !== "EXPENSE" &&
            this.modal.itemType !== "INCOME";
          break;
        case 2:
          state = this.modal.itemId === 0;
          break;
        case 3:
          state =
            this.modal.amount.length === 0 || parseInt(this.modal.amount) === 0;
          break;
        default:
          state = true;
          break;
      }
      return state;
    },
    modalItems() {
      if (this.modal.itemType === "EXPENSE") {
        return this.modal.expenses;
      } else if (this.modal.itemType === "INCOME") {
        return this.modal.incomes;
      } else {
        return [];
      }
    },
    selectedItemsName() {
      const item = this.modalItems.find(item => item.id === this.modal.itemId);
      return item ? item.name : "";
    }
  },
  created() {
    this.fetchQuotation();
    this.fetchModalExpenses();
    this.fetchModalIncomes();
  },
  methods: {
    async fetchQuotation() {
      await ApiService.get(`operation/get-quotation.req.php?pnr=${this.pnr}`)
        .then(({ data }) => {
          this.expenses = data.data.expenses;
          this.incomes = data.data.incomes;
        })
        .catch(({ response }) => {
          alert(response.data.errorMessage);
        });
    },
    async addExpenseItem() {
      const postData = {
        activity_pnr: this.pnr,
        expense_id: this.modal.itemId,
        amount: parseFloat(this.modal.amount),
        description: this.modal.description
      };

      await ApiService.post(`operation/quotation-expense.req.php`, postData)
        .then(() => {
          this.fetchQuotation();
        })
        .catch(({ response }) => {
          alert(response.data.errorMessage);
        });
    },
    async addIncomeItem() {
      const postData = {
        activity_pnr: this.pnr,
        income_id: this.modal.itemId,
        amount: parseFloat(this.modal.amount),
        description: this.modal.description
      };

      await ApiService.post(`operation/quotation-income.req.php`, postData)
        .then(() => {
          this.fetchQuotation();
        })
        .catch(({ response }) => {
          alert(response.data.errorMessage);
        });
    },
    handleModalCancel() {
      this.modal.activeStep = 1;
      this.modal.itemType = "";
      this.modal.itemId = 0;
      this.modal.amount = 0;
      this.modal.description = "";
    },
    fetchModalExpenses() {
      ApiService.get("accounting/expense-item/listings.req.php")
        .then(({ data }) => {
          this.modal.expenses = data.data;
        })
        .catch(({ response }) => {
          alert(response.data.errorMessage);
        });
    },
    fetchModalIncomes() {
      ApiService.get("accounting/income-item/listings.req.php")
        .then(({ data }) => {
          this.modal.incomes = data.data;
        })
        .catch(({ response }) => {
          alert(response.data.errorMessage);
        });
    },
    handleModalOk() {
      if (this.modal.itemType === "EXPENSE") {
        this.addExpenseItem();
      } else if (this.modal.itemType === "INCOME") {
        this.addIncomeItem();
      }
    }
  }
};
</script>

<style>
.option {
  cursor: pointer;
  border-width: 2px;
}
.option .option-label .option-body {
  font-size: 1.08rem !important;
  max-width: 80% !important;
}
.option.selectable.selected,
.option.selectable:hover {
  border-color: var(--dark);
  background-color: var(--light);
}
.option.selected {
  border-width: 2px;
}
</style>
