<template>
  <section>
    <div class="card card-custom gutter-b">
      <div class="card-header border-0 py-5">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label font-weight-bolder text-dark"
            >Yolcu Listesi</span
          >
        </h3>
        <div class="card-toolbar">
          <JsonExcel
            v-if="passengers.length"
            class="btn btn-light font-weight-bolder font-size-sm mr-4"
            :data="excelData"
            worksheet="My Worksheet"
            :name="`OPERATION_${pnr}.xls`"
          >
            Export to Excel
          </JsonExcel>
          <button
            @click="createPDF"
            class="btn btn-light font-weight-bolder font-size-sm mr-4"
            :disabled="isCreatingPdf"
          >
            PDF Oluştur
          </button>
          <button
            @click="$bvModal.show('modal-add-participant')"
            class="btn btn-light-danger font-weight-bolder font-size-sm"
          >
            <span class="svg-icon">
              <inline-svg src="media/svg/icons/Communication/Add-user.svg" />
            </span>
            Yolcu Ekle
          </button>
        </div>
      </div>
      <div class="card-body pt-0 pb-3">
        <div class="table-responsive">
          <div
            class="
              d-flex
              flex-column
              align-items-center
              justify-content-center
              h-100
            "
            v-if="passengers.length === 0"
          >
            <img
              src="media/svg/illustrations/list-is-empty-transparent.svg"
              style="width: 25%"
            />
            <label
              class="
                d-block
                font-weight-bold font-size-h6
                text-muted text-center
                py-5
              "
              >Lütfen listeye yolcu ekleyin.</label
            >
          </div>
          <PassengerTable
            v-else
            :passengers="passengers"
            @edit="openEditModal"
            @remove="removePassenger"
            @copy="openCopyModal"
          ></PassengerTable>
        </div>
      </div>
    </div>

    <!--Begin: SET PRICE-->
    <b-modal
      id="modal-add-participant"
      ref="modal-add-participant"
      size="lg"
      no-fade
      centered
      header-class="py-3"
      footer-class="py-3"
      @cancel="handleAddModalCancel"
      @hide="handleAddModalCancel"
      @ok="handleAddModalOk"
    >
      <template #modal-header="{ cancel }">
        <button
          type="button"
          @click="cancel"
          class="btn btn-icon btn-hover-light btn-sm"
        >
          <i class="ki ki-close icon-nm text-dark"></i>
        </button>
        <div
          class="
            d-flex
            flex-fill
            align-items-center
            justify-content-center
            pt-2
          "
        >
          <div
            class="d-flex flex-column align-items-center"
            style="margin-left: -32px"
          >
            <h5>Yolcu Ekle</h5>
          </div>
        </div>
      </template>

      <div class="row">
        <div class="col-4 col-lg-4">
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">Kaynak</label>
            <div class="radio-inline">
              <label class="radio">
                <input
                  type="radio"
                  value="ONLINE"
                  name="radios3"
                  v-model="addModal.sourceOption"
                />
                <span></span>
                Online
              </label>
              <label class="radio">
                <input
                  type="radio"
                  value="SUPPLIER"
                  name="radios3"
                  v-model="addModal.sourceOption"
                />
                <span></span>
                Acente
              </label>
            </div>
          </div>
        </div>
        <div
          :class="[
            { 'col-lg-8': addModal.sourceOption === 'ONLINE' },
            { 'col-lg-4': addModal.sourceOption === 'SUPPLIER' }
          ]"
        >
          <div class="form-group" v-if="addModal.sourceOption === 'ONLINE'">
            <label class="text-body font-weight-bold pl-1"
              >Rezervasyon Numarası</label
            >
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">ZNF</span>
              </div>
              <input
                type="text"
                class="form-control"
                maxlength="8"
                placeholder="8 haneli ZNF ile başlayan rezervasyon numarasını girin"
                v-model="addModal.source"
              />
            </div>
          </div>
          <div class="form-group" v-if="addModal.sourceOption === 'SUPPLIER'">
            <label class="text-body font-weight-bold pl-1">Acente Adı</label>
            <input
              type="text"
              class="form-control"
              placeholder="Acente adı girin"
              v-model="addModal.source"
            />
          </div>
        </div>
        <div class="col-4 col-lg-4" v-if="addModal.sourceOption === 'SUPPLIER'">
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">Ödeme Durumu</label>
            <select class="form-control" v-model="addModal.payment">
              <option value="0" disabled>Seçin</option>
              <option value="1">Alındı</option>
              <option value="2">Araçta Alınacak</option>
            </select>
          </div>
        </div>
      </div>

      <div class="separator separator-dashed gutter-b"></div>

      <div class="row">
        <div class="col-4 col-lg-4">
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">Cinsiyet</label>
            <select class="form-control" v-model="addModal.gender">
              <option value="" disabled>Seçin</option>
              <option value="M">Erkek</option>
              <option value="F">Kadın</option>
            </select>
          </div>
        </div>
        <div class="col-4 col-lg-4">
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">Adı</label>
            <input
              type="text"
              class="form-control"
              placeholder="Yolcu adı"
              v-model="addModal.name"
            />
          </div>
        </div>
        <div class="col-4 col-lg-4">
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">Soyadı</label>
            <input
              type="text"
              class="form-control"
              placeholder="Yolcu soyadı"
              v-model="addModal.surname"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-4 col-lg-4">
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">Uyruk</label>
            <select class="form-control" v-model="addModal.nationalityISO">
              <option value="" disabled>Seçin</option>
              <option
                v-for="(country, key) in countries"
                :key="key"
                :value="key"
              >
                {{ country }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-4 col-lg-4">
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1"
              >TC/Pasaport No</label
            >
            <input
              type="text"
              class="form-control"
              placeholder="TC / Pasaport numarası"
              v-model="addModal.passportNo"
            />
          </div>
        </div>
        <div class="col-4 col-lg-4">
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1"
              >Telefon Numarası</label
            >
            <input
              type="tel"
              class="form-control"
              placeholder="Ülke kodu ile girin"
              v-model="addModal.phone"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-4 col-lg-4">
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">Hes Kodu</label>
            <input
              type="text"
              class="form-control"
              placeholder="Hes kodunu girin"
              v-model="addModal.hesCode"
            />
          </div>
        </div>
        <div class="col-4 col-lg-4">
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">Alınma Yeri</label>
            <input
              type="text"
              class="form-control"
              placeholder="Alınma yerini girin"
              v-model="addModal.pickupPoint"
            />
          </div>
        </div>
        <div class="col-4 col-lg-4">
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">Alınma Saati</label>
            <input
              type="time"
              class="form-control"
              v-model="addModal.pickupTime"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-lg-12">
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">Yolcu Notu</label>
            <input type="text" class="form-control" v-model="addModal.note" />
          </div>
        </div>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div
          class="
            d-flex
            flex-row
            align-items-center
            justify-content-between
            flex-fill
          "
        >
          <button
            type="button"
            class="btn btn-hover-transparent-dark font-weight-bolder py-4"
            @click="cancel"
          >
            İptal et
          </button>

          <button
            type="button"
            class="btn btn-dark font-weight-bolder px-5 py-4"
            :disabled="isAddModalDisabled"
            @click="ok"
          >
            Kaydet
          </button>
        </div>
      </template>
    </b-modal>
    <!--End: SET PRICE-->

    <!--Begin: SET PRICE-->
    <b-modal
      id="modal-edit-participant"
      ref="modal-edit-participant"
      size="lg"
      no-fade
      centered
      header-class="py-3"
      footer-class="py-3"
      @cancel="handleEditModalCancel"
      @hide="handleEditModalCancel"
      @ok="handleEditModalOk"
    >
      <template #modal-header="{ cancel }">
        <button
          type="button"
          @click="cancel"
          class="btn btn-icon btn-hover-light btn-sm"
        >
          <i class="ki ki-close icon-nm text-dark"></i>
        </button>
        <div
          class="
            d-flex
            flex-fill
            align-items-center
            justify-content-center
            pt-2
          "
        >
          <div
            class="d-flex flex-column align-items-center"
            style="margin-left: -32px"
          >
            <h5>Yolcu Düzenle</h5>
          </div>
        </div>
      </template>

      <div class="row">
        <div class="col-4 col-lg-4">
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">Kaynak</label>
            <div class="radio-inline">
              <label class="radio">
                <input
                  type="radio"
                  value="ONLINE"
                  name="radios3"
                  v-model="editModal.sourceOption"
                />
                <span></span>
                Online
              </label>
              <label class="radio">
                <input
                  type="radio"
                  value="SUPPLIER"
                  name="radios3"
                  v-model="editModal.sourceOption"
                />
                <span></span>
                Acente
              </label>
            </div>
          </div>
        </div>
        <div
          :class="[
            { 'col-lg-8': editModal.sourceOption === 'ONLINE' },
            { 'col-lg-4': editModal.sourceOption === 'SUPPLIER' }
          ]"
        >
          <div class="form-group" v-if="editModal.sourceOption === 'ONLINE'">
            <label class="text-body font-weight-bold pl-1"
              >Rezervasyon Numarası</label
            >
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">ZNF</span>
              </div>
              <input
                type="text"
                class="form-control"
                maxlength="8"
                placeholder="8 haneli ZNF ile başlayan rezervasyon numarasını girin"
                v-model="editModal.source"
              />
            </div>
          </div>
          <div class="form-group" v-if="editModal.sourceOption === 'SUPPLIER'">
            <label class="text-body font-weight-bold pl-1">Acente Adı</label>
            <input
              type="text"
              class="form-control"
              placeholder="Acente adı girin"
              v-model="editModal.source"
            />
          </div>
        </div>
        <div
          class="col-4 col-lg-4"
          v-if="editModal.sourceOption === 'SUPPLIER'"
        >
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">Ödeme Durumu</label>
            <select class="form-control" v-model="editModal.payment">
              <option value="0" disabled>Seçin</option>
              <option value="1">Alındı</option>
              <option value="2">Araçta Alınacak</option>
            </select>
          </div>
        </div>
      </div>

      <div class="separator separator-dashed gutter-b"></div>

      <div class="row">
        <div class="col-4 col-lg-4">
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">Cinsiyet</label>
            <select class="form-control" v-model="editModal.gender">
              <option value="" disabled>Seçin</option>
              <option value="M">Erkek</option>
              <option value="F">Kadın</option>
            </select>
          </div>
        </div>
        <div class="col-4 col-lg-4">
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">Adı</label>
            <input
              type="text"
              class="form-control"
              placeholder="Yolcu adı"
              v-model="editModal.name"
            />
          </div>
        </div>
        <div class="col-4 col-lg-4">
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">Soyadı</label>
            <input
              type="text"
              class="form-control"
              placeholder="Yolcu soyadı"
              v-model="editModal.surname"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-4 col-lg-4">
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">Uyruk</label>
            <select class="form-control" v-model="editModal.nationalityISO">
              <option value="" disabled>Seçin</option>
              <option
                v-for="(country, key) in countries"
                :key="key"
                :value="key"
              >
                {{ country }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-4 col-lg-4">
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1"
              >TC/Pasaport No</label
            >
            <input
              type="text"
              class="form-control"
              placeholder="TC / Pasaport numarası"
              v-model="editModal.passportNo"
            />
          </div>
        </div>
        <div class="col-4 col-lg-4">
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1"
              >Telefon Numarası</label
            >
            <input
              type="tel"
              class="form-control"
              placeholder="Ülke kodu ile girin"
              v-model="editModal.phone"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-4 col-lg-4">
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">Hes Kodu</label>
            <input
              type="text"
              class="form-control"
              placeholder="Hes kodunu girin"
              v-model="editModal.hesCode"
            />
          </div>
        </div>
        <div class="col-4 col-lg-4">
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">Alınma Yeri</label>
            <input
              type="text"
              class="form-control"
              placeholder="Alınma yerini girin"
              v-model="editModal.pickupPoint"
            />
          </div>
        </div>
        <div class="col-4 col-lg-4">
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">Alınma Saati</label>
            <input
              type="time"
              class="form-control"
              v-model="editModal.pickupTime"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-lg-12">
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">Yolcu Notu</label>
            <input type="text" class="form-control" v-model="editModal.note" />
          </div>
        </div>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div
          class="
            d-flex
            flex-row
            align-items-center
            justify-content-between
            flex-fill
          "
        >
          <button
            type="button"
            class="btn btn-hover-transparent-dark font-weight-bolder py-4"
            @click="cancel"
          >
            İptal et
          </button>

          <button
            type="button"
            class="btn btn-dark font-weight-bolder px-5 py-4"
            :disabled="isEditModalDisabled"
            @click="ok"
          >
            Kaydet
          </button>
        </div>
      </template>
    </b-modal>
    <!--End: SET PRICE-->

    <!--Begin: SET PRICE-->
    <b-modal
      id="modal-copy-participant"
      ref="modal-copy-participant"
      size="md"
      no-fade
      centered
      header-class="py-3"
      footer-class="py-3"
      @cancel="handleCopyModalCancel"
      @hide="handleCopyModalCancel"
      @ok="handleCopyModalOk"
    >
      <template #modal-header="{ cancel }">
        <button
          type="button"
          @click="cancel"
          class="btn btn-icon btn-hover-light btn-sm"
        >
          <i class="ki ki-close icon-nm text-dark"></i>
        </button>
        <div
          class="
            d-flex
            flex-fill
            align-items-center
            justify-content-center
            pt-2
          "
        >
          <div
            class="d-flex flex-column align-items-center"
            style="margin-left: -32px"
          >
            <h5>Yolcu Kopyala</h5>
          </div>
        </div>
      </template>
      <div class="row">
        <div class="col-12 col-lg-12">
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">PNR Numarası</label>
            <input
              type="text"
              maxlength="5"
              class="form-control"
              v-model="copyModal.pnr"
            />
            <span class="form-text text-muted"
              >Yolcuyu kopyalamak istediğiniz tura ait PNR numarasını
              girin</span
            >
          </div>
        </div>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div
          class="
            d-flex
            flex-row
            align-items-center
            justify-content-between
            flex-fill
          "
        >
          <button
            type="button"
            class="btn btn-hover-transparent-dark font-weight-bolder py-4"
            @click="cancel"
          >
            İptal et
          </button>

          <button
            type="button"
            class="btn btn-dark font-weight-bolder px-5 py-4"
            :disabled="copyModal.pnr.length !== 5"
            @click="ok"
          >
            Kaydet
          </button>
        </div>
      </template>
    </b-modal>
    <!--End: SET PRICE-->

    <Notification
      :state="notification.state"
      :message="notification.message"
      v-if="notification.status"
    />
  </section>
</template>

<script>
import ApiService from "@/core/services/api.service";
import CountryList from "@/data/country-list";
import JsonExcel from "vue-json-excel";
import PassengerTable from "@/view/pages/operation/components/details/passenger-list/PassengerTable";
import Notification from "@/view/content/notification/Notification";

export default {
  components: {
    PassengerTable,
    JsonExcel,
    Notification
  },
  data() {
    return {
      pnr: this.$route.params.pnr,
      passengers: [],
      addModal: {
        sourceOption: "ONLINE",
        source: "",
        name: "",
        surname: "",
        gender: "",
        passportNo: "",
        hesCode: "",
        nationalityISO: "",
        phone: "",
        payment: 1,
        note: "",
        pickupPoint: "",
        pickupTime: ""
      },
      editModal: {
        id: 0,
        sourceOption: "ONLINE",
        source: "",
        name: "",
        surname: "",
        gender: "",
        passportNo: "",
        hesCode: "",
        nationalityISO: "",
        phone: "",
        payment: 1,
        note: "",
        pickupPoint: "",
        pickupTime: ""
      },
      copyModal: {
        passengerId: 0,
        pnr: ""
      },
      countries: CountryList,
      isCreatingPdf: false,
      notification: {
        status: false,
        state: "",
        message: ""
      }
    };
  },
  watch: {
    "addModal.sourceOption": function(newVal) {
      if (newVal === "ONLINE") {
        this.addModal.payment = 1;
      } else if (newVal === "SUPPLIER") {
        this.addModal.payment = 0;
      }
    },
    "editModal.sourceOption": function(newVal) {
      if (newVal === "ONLINE") {
        this.editModal.payment = 1;
      }
    },
    "addModal.source": function(newVal) {
      if (this.addModal.sourceOption === "ONLINE" && newVal.length === 8) {
        this.getPickupDetails();
      }
    }
  },
  computed: {
    isAddModalDisabled() {
      if (
        !this.addModal.source ||
        !this.addModal.gender ||
        !this.addModal.name ||
        !this.addModal.surname ||
        !this.addModal.nationalityISO ||
        !this.addModal.passportNo ||
        !this.addModal.payment ||
        !this.addModal.pickupPoint ||
        !this.addModal.pickupTime
      ) {
        return true;
      }
      return false;
    },
    isEditModalDisabled() {
      if (
        !this.editModal.source ||
        !this.editModal.gender ||
        !this.editModal.name ||
        !this.editModal.surname ||
        !this.editModal.nationalityISO ||
        !this.editModal.passportNo ||
        !this.editModal.payment ||
        !this.editModal.pickupPoint ||
        !this.editModal.pickupTime
      ) {
        return true;
      }
      return false;
    },
    excelData() {
      const arr = [];
      this.passengers.forEach(passenger => {
        arr.push({
          ÜLKE: passenger.nationalityISO,
          ADI: passenger.name,
          SOYADI: passenger.surname,
          "TC KİMLİK/PASAPORT NO": passenger.passportNo,
          CİNSİYET: passenger.gender === "M" ? "E" : "K",
          TELEFON: passenger.phone,
          "HES KODU": passenger.hesCode
        });
      });
      return arr;
    }
  },
  mounted() {
    this.get();
  },
  methods: {
    get() {
      ApiService.get(`operation/get-passengers.req.php?pnr=${this.pnr}`)
        .then(({ data }) => {
          this.passengers = data.passengers;
        })
        .catch(({ response }) => {
          alert(response.data.errorMessage);
        });
    },
    getPickupDetails() {
      ApiService.get(
        `operation/get-passenger-details-by-reservation-no.req.php?reservationNo=${this.addModal.source}`
      ).then(({ data }) => {
        this.addModal.pickupPoint = data.details.pickupPoint;
        this.addModal.phone = data.details.mobile;
        this.addModal.nationalityISO = data.details.country;
      });
    },
    handleAddModalCancel() {
      this.addModal = {
        sourceOption: "ONLINE",
        source: "",
        name: "",
        surname: "",
        gender: "",
        passportNo: "",
        hesCode: "",
        nationalityISO: "",
        phone: "",
        payment: 1,
        note: "",
        pickupPoint: "",
        pickupTime: ""
      };
    },
    handleEditModalCancel() {
      this.editModal = {
        id: 0,
        sourceOption: "ONLINE",
        source: "",
        name: "",
        surname: "",
        gender: "",
        passportNo: "",
        hesCode: "",
        nationalityISO: "",
        phone: "",
        payment: 1,
        note: "",
        pickupPoint: "",
        pickupTime: ""
      };
    },
    handleCopyModalCancel() {
      this.copyModal = {
        passengerId: 0,
        pnr: ""
      };
    },
    handleAddModalOk() {
      const postData = {
        pnr: this.pnr,
        sourceType: this.addModal.sourceOption,
        source: this.addModal.source,
        payment: parseInt(this.addModal.payment),
        gender: this.addModal.gender,
        name: this.addModal.name,
        surname: this.addModal.surname,
        passportNo: this.addModal.passportNo,
        phone: this.addModal.phone,
        hesCode: this.addModal.hesCode,
        nationality: this.addModal.nationalityISO,
        note: this.addModal.note,
        pickupPoint: this.addModal.pickupPoint,
        pickupTime: this.addModal.pickupTime
      };
      ApiService.post("operation/add-passenger.req.php", postData)
        .then(() => {
          this.get();
        })
        .catch(({ response }) => {
          alert(response.data.errorMessage);
        });
    },
    openEditModal(id) {
      let passenger = this.passengers.find(passenger => passenger.id === id);
      if (passenger) {
        this.editModal.id = passenger.id;
        this.editModal.sourceOption = passenger.sourceType;
        this.editModal.source = passenger.source;
        this.editModal.payment = parseInt(passenger.payment);
        this.editModal.gender = passenger.gender;
        this.editModal.name = passenger.name;
        this.editModal.surname = passenger.surname;
        this.editModal.passportNo = passenger.passportNo;
        this.editModal.nationalityISO = passenger.nationalityISO;
        this.editModal.phone = passenger.phone;
        this.editModal.hesCode = passenger.hesCode;
        this.editModal.note = passenger.note;
        this.editModal.pickupPoint = passenger.pickupPoint;
        this.editModal.pickupTime = passenger.pickupTime;
      }
      this.$bvModal.show("modal-edit-participant");
    },
    handleEditModalOk() {
      const postData = {
        id: this.editModal.id,
        sourceType: this.editModal.sourceOption,
        source: this.editModal.source,
        payment: parseInt(this.editModal.payment),
        gender: this.editModal.gender,
        name: this.editModal.name,
        surname: this.editModal.surname,
        passportNo: this.editModal.passportNo,
        phone: this.editModal.phone,
        hesCode: this.editModal.hesCode,
        nationality: this.editModal.nationalityISO,
        note: this.editModal.note,
        pickupPoint: this.editModal.pickupPoint,
        pickupTime: this.editModal.pickupTime
      };
      ApiService.post("operation/update-passenger.req.php", postData)
        .then(() => {
          this.get();
        })
        .catch(({ response }) => {
          alert(response.data.errorMessage);
        });
    },
    openCopyModal(id) {
      this.copyModal.passengerId = id;
      this.$bvModal.show("modal-copy-participant");
    },
    handleCopyModalOk() {
      const postData = {
        pnr: this.copyModal.pnr,
        passengerId: this.copyModal.passengerId
      };
      ApiService.post("operation/copy-passenger.req.php", postData)
        .then(() => {
          this.notify('success', 'Başarılı! Yolcu kopyalandı.');
        })
        .catch(({ response }) => {
          this.notify('danger', response.data.errorMessage);
        });
    },
    removePassenger(id) {
      const postData = {
        id: id
      };
      ApiService.post("operation/remove-passenger.req.php", postData)
        .then(() => {
          this.get();
        })
        .catch(({ response }) => {
          alert(response.data.errorMessage);
        });
    },
    createPDF() {
      this.isCreatingPdf = true;
      const postData = {
        pnr: this.pnr
      };
      ApiService.post("operation/create-passenger-list.req.php", postData)
        .then(({ data }) => {
          this.isCreatingPdf = false;
          window.open(data.url, "_blank").focus();
        })
        .catch(({ response }) => {
          this.isCreatingPdf = true;
          alert(response.data.errorMessage);
        });
    },
    notify(state, message) {
      this.notification.state = state;
      this.notification.message = message;
      this.notification.status = true;
      
      this.notification.timeout = setTimeout(() => {
        this.notification.status = false;
      }, 4000);
    },
  }
};
</script>

<style scoped>
select {
  -webkit-appearance: auto;
}
</style>
