<template>
  <div>
    <section>
      <QuotationItem
        v-for="(expense, index) in expenses"
        :key="`expense__${index}`"
        :id="expense.id"
        :name="expense.name"
        :amount="expense.amount"
        :description="expense.description"
        type="EXPENSE"
        @itemClick="onExpenseItemClick"
      />
    </section>
    <section>
      <QuotationItem
        v-for="(income, index) in incomes"
        :key="`income__${index}`"
        :id="income.id"
        :name="income.name"
        :amount="income.amount"
        :description="income.description"
        type="INCOME"
        @itemClick="onIncomeItemClick"
      />
    </section>
    <section>
      <div class="separator separator-dashed gutter-b gutter-t"></div>
      <div
        class="d-flex flex-column align-items-end my-5 bg-light borderless rounded px-5 py-0"
      >
        <div class="d-flex flex-column flex-grow-1 text-right">
          <span class="font-weight-bold text-muted font-size-lg"
            >Net Tutar</span
          >
        </div>
        <span
          class="font-weight-bolder py-1 font-size-h3"
          :class="netAmountClass"
          >{{ netAmountText }}</span
        >
      </div>
    </section>
    <b-modal
      id="modal-edit"
      ref="modal-edit"
      no-fade
      centered
      header-class="py-3"
      footer-class="py-3"
      @cancel="handleModalCancel"
      @hide="handleModalCancel"
      @ok="handleModalOk"
    >
      <template #modal-header="{ cancel }">
        <button
          type="button"
          @click="cancel"
          class="btn btn-icon btn-hover-light btn-sm"
        >
          <i class="ki ki-close icon-nm text-dark"></i>
        </button>
        <div
          class="d-flex flex-fill align-items-center justify-content-center pt-2"
        >
          <div
            class="d-flex flex-column align-items-center"
            style="margin-left: -32px;"
          >
            <h5>{{ modal.name }}</h5>
          </div>
        </div>
        <button
          class="btn btn-sm btn-icon btn-light-danger"
          @click="removeItem"
        >
          <i class="flaticon2-trash icon-md"></i>
        </button>
      </template>

      <div class="max-h-300px">
        <div class="form-group">
          <label class="text-body font-weight-bold pl-1">{{
            modal.name
          }}</label>
          <input
            type="number"
            class="form-control form-control-lg"
            :placeholder="`${modal.name} girin`"
            v-model="modal.amount"
          />
        </div>
        <div class="form-group">
          <label class="text-body font-weight-bold pl-1"
            >Açıklama (İsteğe bağlı)</label
          >
          <input
            type="text"
            class="form-control form-control-lg"
            placeholder="Açıklama girin"
            v-model="modal.description"
          />
        </div>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div
          class="d-flex flex-row align-items-center justify-content-between flex-fill"
        >
          <button
            type="button"
            class="btn btn-hover-transparent-dark font-weight-bolder py-4"
            @click="cancel"
          >
            İptal et
          </button>

          <button
            type="button"
            class="btn btn-dark font-weight-bolder px-5 py-4"
            :disabled="
              modal.amount.length === 0 || parseInt(modal.amount) === 0
            "
            @click="ok"
          >
            Kaydet
          </button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import QuotationItem from "@/view/pages/operation/components/details/quotation/QuotationItem.vue";

export default {
  components: {
    QuotationItem
  },
  props: {
    expenses: {
      type: Array,
      default: () => []
    },
    incomes: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      modal: {
        id: 0,
        name: "",
        amount: 0,
        description: "",
        type: ""
      }
    };
  },
  computed: {
    totalIncome() {
      return this.incomes.reduce((acu, cur) => {
        return acu + cur.amount;
      }, 0);
    },
    totalExpense() {
      return this.expenses.reduce((acu, cur) => {
        return acu + cur.amount;
      }, 0);
    },
    sign() {
      if (this.totalIncome > this.totalExpense) {
        return "+";
      } else if (this.totalIncome < this.totalExpense) {
        return "-";
      } else {
        return "";
      }
    },
    netAmountClass() {
      if (this.sign === "+") {
        return "text-success";
      } else if (this.sign === "-") {
        return "text-danger";
      } else {
        return "";
      }
    },
    netAmount() {
      return Math.abs(this.totalIncome - this.totalExpense).toFixed(2);
    },
    netAmountText() {
      return `${this.sign} ${this.netAmount} TRY`;
    }
  },
  methods: {
    onExpenseItemClick(itemId) {
      const expense = this.expenses.find(item => item.id === itemId);
      this.modal = { ...expense, type: "EXPENSE" };
      this.$bvModal.show("modal-edit");
    },
    onIncomeItemClick(itemId) {
      const income = this.incomes.find(item => item.id === itemId);
      this.modal = { ...income, type: "INCOME" };
      this.$bvModal.show("modal-edit");
    },
    handleModalCancel() {
      this.modal = {
        id: 0,
        name: "",
        amount: 0,
        type: ""
      };
    },
    handleModalOk() {
      if (this.modal.type === "EXPENSE") {
        this.editExpenseItem();
      } else if (this.modal.type === "INCOME") {
        this.editIncomeItem();
      }
    },
    async editIncomeItem() {
      const postData = {
        id: this.modal.id,
        amount: parseFloat(this.modal.amount),
        description: this.modal.description
      };

      await ApiService.post(`operation/quotation-edit-income.req.php`, postData)
        .then(() => {
          this.$emit("change");
        })
        .catch(({ response }) => {
          alert(response.data.errorMessage);
        });
    },
    async deleteIncomeItem() {
      const postData = {
        id: this.modal.id
      };

      await ApiService.post(
        `operation/quotation-delete-income.req.php`,
        postData
      )
        .then(() => {
          this.$bvModal.hide("modal-edit");
          this.$emit("change");
        })
        .catch(({ response }) => {
          alert(response.data.errorMessage);
        });
    },
    async editExpenseItem() {
      const postData = {
        id: this.modal.id,
        amount: parseFloat(this.modal.amount),
        description: this.modal.description
      };

      await ApiService.post(
        `operation/quotation-edit-expense.req.php`,
        postData
      )
        .then(() => {
          this.$emit("change");
        })
        .catch(({ response }) => {
          alert(response.data.errorMessage);
        });
    },
    async deleteExpenseItem() {
      const postData = {
        id: this.modal.id
      };

      await ApiService.post(
        `operation/quotation-delete-expense.req.php`,
        postData
      )
        .then(() => {
          this.$bvModal.hide("modal-edit");
          this.$emit("change");
        })
        .catch(({ response }) => {
          alert(response.data.errorMessage);
        });
    },
    removeItem() {
      if (this.modal.type === "EXPENSE") {
        this.deleteExpenseItem();
      } else if (this.modal.type === "INCOME") {
        this.deleteIncomeItem();
      }
    }
  }
};
</script>

<style></style>
