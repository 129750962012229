<template>
  <section>
    <div class="card card-custom gutter-b">
      <div class="card-body">
        <span
          class="svg-icon svg-icon-2x"
          :class="[
            { 'svg-icon-success': companyName },
            { 'svg-icon-warning': !companyName }
          ]"
        >
          <inline-svg src="media/svg/icons/Code/Compiling.svg" />
        </span>
        <div class="text-dark font-weight-bolder font-size-h3 mt-3">
          {{ companyNameText }}
        </div>
        <span class="text-muted font-weight-bold mt-1"
          >Operasyonu Gerçekleştiren</span
        >
        <a
          @click="openModal"
          href="javascript:;"
          class="font-weight-normal ml-3 mt-1"
          :class="[
            { 'text-success': companyName },
            { 'text-warning': !companyName }
          ]"
          >{{ actionButtonText }}</a
        >
      </div>
    </div>

    <!--Begin: MODAL -->
    <b-modal
      id="modal-transport-company"
      ref="modal-transport-company"
      no-fade
      centered
      header-class="py-3"
      footer-class="py-3"
      @cancel="handleModalCancel"
      @hide="handleModalCancel"
      @ok="handleModalOk"
    >
      <template #modal-header="{ cancel }">
        <button
          type="button"
          @click="cancel"
          class="btn btn-icon btn-hover-light btn-sm"
        >
          <i class="ki ki-close icon-nm text-dark"></i>
        </button>
        <div
          class="
            d-flex
            flex-fill
            align-items-center
            justify-content-center
            pt-2
          "
        >
          <div
            class="d-flex flex-column align-items-center"
            style="margin-left: -32px"
          >
            <h5>Taşımacı Firma</h5>
          </div>
        </div>
      </template>

      <div class="form-group">
        <label class="text-body font-weight-bold pl-1">Firma</label>
        <select
          class="form-control"
          ref="companySelect"
          v-model="modal.companyId"
        >
          <option value="" disabled>Seçin</option>
          <option
            v-for="(item, index) in modal.companies"
            :key="`company_${index}`"
            :value="item.id"
            >{{ item.company.name }}</option
          >
        </select>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div
          class="
            d-flex
            flex-row
            align-items-center
            justify-content-between
            flex-fill
          "
        >
          <button
            type="button"
            class="btn btn-hover-transparent-dark font-weight-bolder py-4"
            @click="cancel"
          >
            İptal et
          </button>

          <button
            type="button"
            class="btn btn-dark font-weight-bolder px-5 py-4"
            :disabled="!modal.companyId"
            @click="ok"
          >
            Kaydet
          </button>
        </div>
      </template>
    </b-modal>
    <!--End: MODAL-->
  </section>
</template>

<script>
import ApiService from "@/core/services/api.service";

export default {
  props: {
    pnr: {
      type: String,
      required: true
    },
    companyName: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      modal: {
        companies: [],
        companyId: ""
      }
    };
  },
  computed: {
    companyNameText() {
      if (this.companyName) {
        return this.companyName;
      } else {
        return "Tanımsız";
      }
    },
    actionButtonText() {
      if (this.companyName) {
        return "Düzenle";
      } else {
        return "Tanımla";
      }
    }
  },
  methods: {
    handleModalCancel() {
      this.modal.companyId = "";
    },
    openModal() {
      this.getTransportCompanies();
      this.$bvModal.show("modal-transport-company");
    },
    getTransportCompanies() {
      ApiService.get("transport-company/listings.req.php")
        .then(({ data }) => {
          this.modal.companies = [...data.data];
        })
        .catch(() => {});
    },
    handleModalOk() {
      const postData = {
        pnr: this.pnr,
        companyId: this.modal.companyId
      };

      ApiService.post("operation/set-transport-company.req.php", postData)
        .then(() => {
          this.$emit("change");
        })
        .catch(({ response }) => {
          alert(response.data.errorMessage);
        });
    }
  }
};
</script>
