<template>
  <table class="table">
    <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Kaynak</th>
        <th scope="col">Adı Soyadı</th>
        <th scope="col">Cinsiyet</th>
        <th scope="col">TC/Pasaport No</th>
        <th scope="col">Uyruk</th>
        <th scope="col">İletişim</th>
        <th scope="col">Alınma Saati</th>
        <th scope="col">Alınma Yeri</th>
        <th scope="col">Ödeme</th>
        <th scope="col"></th>
      </tr>
    </thead>
    <tbody>
      <PassengerItem
        v-for="(passenger, index) in passengers"
        :key="passenger.id"
        :id="passenger.id"
        :number="index + 1"
        :source="passenger.source"
        :gender="passenger.gender"
        :name="passenger.name"
        :surname="passenger.surname"
        :passport-no="passenger.passportNo"
        :nationality="passenger.nationality"
        :phone="passenger.phone"
        :pickup-time="passenger.pickupTime"
        :pickup-point="passenger.pickupPoint"
        :payment="passenger.payment"
        @edit="edit"
        @remove="remove"
        @copy="copy"
      ></PassengerItem>
    </tbody>
  </table>
</template>

<script>
import PassengerItem from "@/view/pages/operation/components/details/passenger-list/PassengerItem";
export default {
  props: {
    passengers: Array,
  },
  components: {
    PassengerItem,
  },
  methods: {
    edit(id) {
      this.$emit("edit", id);
    },
    remove(id) {
      this.$emit("remove", id);
    },
    copy(id) {
      this.$emit("copy", id);
    },
  },
};
</script>

<style></style>
