<template>
  <section>
    <div class="row" v-if="!isError">
      <div class="col-md-7 col-lg-12 col-xxl-7">
        <div>
          <Tour
            :pnr="this.pnr"
            :title="this.details.tour.productTitle"
            :summary="this.details.tour.productSummary"
            :image="this.details.tour.productImage"
            :departure-date-time="this.details.tour.departureDateTime"
            :pax="this.details.tour.pax"
            :product-id="details.tour.productId"
          ></Tour>
        </div>
        <div>
          <BookingList :bookings="details.bookings"></BookingList>
        </div>
      </div>
      <div class="col-md-5 col-lg-12 col-xxl-5">
        <div class="row">
          <div class="col-md-12">
            <TransportCompany
              :pnr="this.pnr"
              :company-name="this.details.transportation.company"
              @change="get"
            />
          </div>
          <div class="col-6 col-md-6">
            <Driver
              :pnr="this.pnr"
              :driver="this.details.transportation.driver"
              @update="updateDriver"
            ></Driver>
          </div>
          <div class="col-6 col-md-6">
            <Vehicle
              :pnr="this.pnr"
              :vehicle="this.details.transportation.vehicle"
              @update="updateVehicle"
            ></Vehicle>
          </div>
        </div>

        <div class="separator separator-dashed gutter-b"></div>
        <div class="row">
          <div class="col-md-12">
            <QuotationWrapper></QuotationWrapper>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xxl-12">
        <PassengerList></PassengerList>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-7">
        <div class="card card-custom bg-light-warning gutter-b">
          <div class="card-header border-0">
            <div class="card-title">
              <h3 class="card-label">
                <span class="d-block text-dark font-weight-bolder"
                  >Operasyon Notu</span
                >
              </h3>
            </div>
            <div class="card-toolbar">
              <button
                class="btn btn-dark btn-sm font-weight-bolder font-size-sm"
                @click="onModalShow"
              >
                {{ details.notes ? "Düzenle" : "Ekle" }}
              </button>
            </div>
          </div>
          <div class="card-body pt-2">
            <template v-if="!details.notes">
              <span class="text-muted font-size-lg">
                <em>Operasyon notu yok</em>
              </span>
            </template>
            <template v-else>
              <div
                class="text-dark font-weight-normal font-size-lg"
                v-html="details.notes"
              ></div>
            </template>
          </div>
        </div>
      </div>
      <div class="col-xl-5">
        <div class="card card-custom bg-light-info gutter-b">
          <div class="card-header border-0">
            <div class="card-title">
              <h3 class="card-label">
                <span class="d-block text-dark font-weight-bolder"
                  >Acente Notu</span
                >
              </h3>
            </div>
            <div class="card-toolbar">
              <button
                class="btn btn-dark btn-sm font-weight-bolder font-size-sm"
                @click="onAgencyNoteModalShow"
              >
                {{ details.agencyNotes ? "Düzenle" : "Ekle" }}
              </button>
            </div>
          </div>
          <div class="card-body pt-2">
            <template v-if="!details.agencyNotes">
              <span class="text-muted font-size-lg">
                <em>Acente notu yok</em>
              </span>
            </template>
            <template v-else>
              <div
                class="text-dark font-weight-normal font-size-lg"
                v-html="details.agencyNotes"
              ></div>
            </template>
          </div>
        </div>
      </div>
      <b-modal
        id="modal-notes"
        ref="modal-notes"
        no-fade
        centered
        scrollable
        size="lg"
        header-class="py-3"
        footer-class="py-3"
        @ok="handleModalOk"
      >
        <template #modal-header="{ cancel }">
          <button
            type="button"
            class="btn btn-icon btn-hover-light btn-sm"
            @click="cancel"
          >
            <i class="ki ki-close icon-nm text-dark"></i>
          </button>
          <div
            class="
              d-flex
              flex-fill
              align-items-center
              justify-content-center
              pt-2
            "
          >
            <div
              class="d-flex flex-column align-items-center"
              style="margin-left: -32px"
            >
              <h5>Rezervasyon notu</h5>
            </div>
          </div>
        </template>

        <div class="max-h-300px">
          <div class="alert alert-danger" role="alert" v-if="modal.error">
            <div class="alert-text">
              Bir şeyler ters gitti! Lütfen tekrar deneyin.
            </div>
          </div>
          <ckeditor
            :editor="editor"
            v-model="modal.notes"
            :config="editorConfig"
          ></ckeditor>
        </div>

        <template #modal-footer="{ ok, cancel }">
          <div
            class="
              d-flex
              flex-row
              align-items-center
              justify-content-between
              flex-fill
            "
          >
            <button
              type="button"
              class="btn btn-hover-transparent-dark font-weight-bolder py-4"
              @click="cancel"
            >
              İptal et
            </button>

            <button
              type="button"
              class="btn btn-dark font-weight-bolder px-5 py-4"
              @click="ok"
            >
              Kaydet
            </button>
          </div>
        </template>
      </b-modal>
      <b-modal
        id="modal-agency-notes"
        ref="modal-agency-notes"
        no-fade
        centered
        scrollable
        size="lg"
        header-class="py-3"
        footer-class="py-3"
        @ok="handleAgencyNotesModalOk"
      >
        <template #modal-header="{ cancel }">
          <button
            type="button"
            class="btn btn-icon btn-hover-light btn-sm"
            @click="cancel"
          >
            <i class="ki ki-close icon-nm text-dark"></i>
          </button>
          <div
            class="
              d-flex
              flex-fill
              align-items-center
              justify-content-center
              pt-2
            "
          >
            <div
              class="d-flex flex-column align-items-center"
              style="margin-left: -32px"
            >
              <h5>Acente notu</h5>
            </div>
          </div>
        </template>

        <div class="max-h-300px">
          <div
            class="alert alert-danger"
            role="alert"
            v-if="agencyNotesModal.error"
          >
            <div class="alert-text">
              Bir şeyler ters gitti! Lütfen tekrar deneyin.
            </div>
          </div>
          <ckeditor
            :editor="editor"
            v-model="agencyNotesModal.notes"
            :config="editorConfig"
          ></ckeditor>
        </div>

        <template #modal-footer="{ ok, cancel }">
          <div
            class="
              d-flex
              flex-row
              align-items-center
              justify-content-between
              flex-fill
            "
          >
            <button
              type="button"
              class="btn btn-hover-transparent-dark font-weight-bolder py-4"
              @click="cancel"
            >
              İptal et
            </button>

            <button
              type="button"
              class="btn btn-dark font-weight-bolder px-5 py-4"
              @click="ok"
            >
              Kaydet
            </button>
          </div>
        </template>
      </b-modal>
    </div>
  </section>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Tour from "@/view/pages/operation/components/details/Tour";
import TransportCompany from "@/view/pages/operation/components/details/TransportCompany";
import Driver from "@/view/pages/operation/components/details/Driver";
import Vehicle from "@/view/pages/operation/components/details/Vehicle";
import BookingList from "@/view/pages/operation/components/details/bookings/BookingList";
import PassengerList from "@/view/pages/operation/components/details/passenger-list/PassengerList";
import QuotationWrapper from "@/view/pages/operation/components/details/quotation/QuotationWrapper.vue";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  components: {
    Tour,
    TransportCompany,
    Driver,
    Vehicle,
    PassengerList,
    BookingList,
    QuotationWrapper,
    ckeditor: CKEditor.component,
  },
  data() {
    return {
      pnr: this.$route.params.pnr,
      isLoading: false,
      isError: false,
      details: null,
      modal: {
        notes: "",
        error: false,
      },
      agencyNotesModal: {
        notes: "",
        error: false,
      },
      editor: ClassicEditor,
      editorConfig: {
        placeholder: "Önemli bilgileri anlatın",
        toolbar: [
          "heading",
          "|",
          "bold",
          "italic",
          "link",
          "bulletedList",
          "numberedList",
          "blockQuote",
        ],
        heading: {
          options: [
            {
              model: "paragraph",
              title: "Paragraph",
              class: "ck-heading_paragraph",
            },
            {
              model: "heading1",
              view: "h1",
              title: "Heading 1",
              class: "ck-heading_heading1",
            },
            {
              model: "heading2",
              view: "h2",
              title: "Heading 2",
              class: "ck-heading_heading2",
            },
          ],
        },
      },
    };
  },
  mounted() {
    this.get();
  },
  methods: {
    get() {
      ApiService.get(`operation/get-details.req.php?pnr=${this.pnr}`)
        .then(({ data }) => {
          this.details = data.details;
          this.isError = false;
        })
        .catch(({ response }) => {
          this.isError = true;
          alert(response.data.errorMessage);
        });
    },

    updateDriver(driver) {
      this.details.transportation.driver = driver;
    },
    updateVehicle(vehicle) {
      this.details.transportation.vehicle = vehicle;
    },
    updateKilometersSpent(km) {
      this.details.quotation.kilometersSpent = km;
    },
    updateFuelSpent(fuelPrice) {
      this.details.quotation.fuelSpentPrice = fuelPrice;
    },
    onModalShow() {
      this.modal.error = false;
      this.modal.notes = this.details.notes;
      this.$bvModal.show("modal-notes");
    },
    onAgencyNoteModalShow() {
      this.agencyNotesModal.error = false;
      this.agencyNotesModal.notes = this.details.agencyNotes;
      this.$bvModal.show("modal-agency-notes");
    },
    handleModalOk(bvModalEvent) {
      bvModalEvent.preventDefault();

      ApiService.post("operation/notes.req.php", {
        pnr: this.pnr,
        notes: this.modal.notes,
      })
        .then(() => {
          this.modal.error = false;
          this.details.notes = this.modal.notes;
          this.$nextTick(() => {
            this.$bvModal.hide("modal-notes");
          });
        })
        .catch(() => {
          this.modal.error = true;
        });
    },
    handleAgencyNotesModalOk(bvModalEvent) {
      bvModalEvent.preventDefault();

      ApiService.post("operation/agency-notes.req.php", {
        pnr: this.pnr,
        notes: this.agencyNotesModal.notes,
      })
        .then(() => {
          this.agencyNotesModal.error = false;
          this.details.agencyNotes = this.agencyNotesModal.notes;
          this.$nextTick(() => {
            this.$bvModal.hide("modal-agency-notes");
          });
        })
        .catch(() => {
          this.agencyNotesModal.error = true;
        });
    },
  },
};
</script>

<style></style>
