<template>
  <div class="card card-custom card-stretch gutter-b">
    <div class="card-body p-15">
      <div>
        <div>
          <div class="row mb-17">
            <div class="col-xxl-5 mb-11 mb-xxl-0">
              <!--begin::Image-->
              <div class="card card-custom">
                <div
                  class="
                    bgi-no-repeat bgi-position-center bgi-size-cover
                    rounded
                    min-h-150px
                    w-100
                  "
                  :style="{
                    backgroundImage: `url(${image})`
                  }"
                ></div>
              </div>
              <!--end::Image-->
            </div>
            <div class="col-xxl-7 pl-xxl-11">
              <router-link
                :to="{ name: 'show-experience', params: { id: productId } }"
                v-slot="{ href }"
              >
                <a :href="href">
                  <h2
                    class="font-size-h2 text-hover-primary font-weight-bolder text-dark mb-4"
                  >
                    {{ title }}
                  </h2>
                </a>
              </router-link>
              <p class="font-size-lg font-weight-normal text-dark-50 mb-4">
                {{ summary }}
              </p>
            </div>
          </div>
          <div class="row">
            <!--begin::Info-->
            <div class="col-6 col-md-4">
              <div class="mb-8 d-flex flex-column">
                <span class="text-muted font-weight-bold mb-4">PNR</span>
                <span class="text-dark font-weight-bolder font-size-lg"
                  >#{{ pnr }}</span
                >
              </div>
            </div>
            <div class="col-6 col-md-4">
              <div class="mb-8 d-flex flex-column">
                <span class="text-muted font-weight-bold mb-4"
                  >Hareket Tarihi</span
                >
                <span class="text-dark font-weight-bolder font-size-lg">{{
                  departureDateText
                }}</span>
              </div>
            </div>
            <div class="col-6 col-md-4">
              <div class="mb-8 d-flex flex-column">
                <span class="text-muted font-weight-bold mb-4"
                  >Katılımcılar</span
                >
                <span class="text-dark font-weight-bolder font-size-lg"
                  >{{ pax }} Pax</span
                >
              </div>
            </div>
            <!--end::Info-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  props: {
    pnr: {
      type: String,
      default: "-"
    },
    title: String,
    summary: String,
    image: String,
    departureDateTime: {
      type: String,
      default: "-"
    },
    participants: Array,
    pax: Number,
    productId: Number
  },
  computed: {
    ...mapGetters(["experienceConfig"]),

    departureDateText() {
      return moment(this.departureDateTime).format("D MMMM YYYY, HH:mm");
    },
    participantsText() {
      let text = [];
      this.participants.forEach(el => {
        text.push(
          el.count + " " + this.experienceConfig(`participant.${el.id}.tr`)
        );
      });
      return text.join(", ");
    }
  },
  created() {
    moment.locale("tr");
  }
};
</script>
