<template>
  <section>
    <div class="card card-custom gutter-b">
      <div class="card-body">
        <span
          class="svg-icon svg-icon-2x"
          :class="[
            { 'svg-icon-success': driver },
            { 'svg-icon-warning': !driver },
          ]"
        >
          <inline-svg src="media/svg/icons/General/User.svg" />
        </span>
        <div class="text-dark font-weight-bolder font-size-h3 mt-3">
          {{ driverText }}
        </div>
        <span class="text-muted font-weight-bold mt-1">Şoför</span>
        <a
          @click="openModal"
          href="javascript:;"
          class="font-weight-normal ml-3 mt-1"
          :class="[{ 'text-success': driver }, { 'text-warning': !driver }]"
          >{{ actionButtonText }}</a
        >
      </div>
    </div>

    <!--Begin: MODAL -->
    <b-modal
      id="modal-driver"
      ref="modal-driver"
      no-fade
      centered
      header-class="py-3"
      footer-class="py-3"
      @shown="focusInput"
      @cancel="handleModalCancel"
      @hide="handleModalCancel"
      @ok="handleModalOk"
    >
      <template #modal-header="{ cancel }">
        <button
          type="button"
          @click="cancel"
          class="btn btn-icon btn-hover-light btn-sm"
        >
          <i class="ki ki-close icon-nm text-dark"></i>
        </button>
        <div
          class="
            d-flex
            flex-fill
            align-items-center
            justify-content-center
            pt-2
          "
        >
          <div
            class="d-flex flex-column align-items-center"
            style="margin-left: -32px"
          >
            <h5>Şoför</h5>
          </div>
        </div>
      </template>

      <div class="form-group">
        <label class="text-body font-weight-bold pl-1">Tam Adı</label>
        <input
          type="text"
          ref="driverInput"
          class="form-control"
          v-model="modal.driver"
        />
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div
          class="
            d-flex
            flex-row
            align-items-center
            justify-content-between
            flex-fill
          "
        >
          <button
            type="button"
            class="btn btn-hover-transparent-dark font-weight-bolder py-4"
            @click="cancel"
          >
            İptal et
          </button>

          <button
            type="button"
            class="btn btn-dark font-weight-bolder px-5 py-4"
            :disabled="!modal.driver"
            @click="ok"
          >
            Kaydet
          </button>
        </div>
      </template>
    </b-modal>
    <!--End: MODAL-->
  </section>
</template>

<script>
import ApiService from '@/core/services/api.service';

export default {
  props: {
    pnr: String,
    driver: {
      type: String,
      default: '-',
    }
  },
  data() {
    return {
      modal: {
        driver: ''
      }
    }
  },
  computed: {
    driverText() {
      if (this.driver) {
        return this.driver
      } else {
        return 'Tanımsız'
      }
    },
    actionButtonText() {
      if (this.driver) {
        return 'Düzenle'
      } else {
        return 'Tanımla'
      }
    }
  },
  methods: {
    handleModalCancel() {
      this.modal.driver = '';
    },
    openModal() {
      this.modal.driver = this.driver;
      this.$bvModal.show('modal-driver');
    },
    handleModalOk() {
      const postData = {
        pnr: this.pnr,
        driver: this.modal.driver
      }
      ApiService.post('operation/set-driver.req.php', postData)
      .then(() => {
        this.$emit("update", postData.driver);
      })
      .catch(({ response }) => {
        alert(response.data.errorMessage);
      });
    },
    focusInput() {
      this.$refs.driverInput.focus();
    }
  }
}
</script>