<template>
  <tr class="pl-0">
    <td class="pl-0">
      <div class="d-flex">
        <span
          v-if="!missingParticipantCount"
          class="svg-icon svg-icon-lg pr-2 svg-icon-success"
        >
          <inline-svg src="media/svg/icons/Code/Done-circle.svg" />
        </span>
        <span
          v-else
          v-b-tooltip.hover.right
          title="Yolcu bilgisi eksik"
          class="svg-icon svg-icon-lg pr-2 svg-icon-warning"
        >
          <inline-svg src="media/svg/icons/Code/Warning-1-circle.svg" />
        </span>
        <div>
          <router-link
            :to="{
              name: 'reservation-details',
              params: { id: reservationId, reservationNumber: reservationNo }
            }"
            v-slot="{ href }"
          >
            <a
              :href="href"
              class="
              text-dark
              font-weight-bolder
              text-hover-primary
              mb-1
              font-size-lg
            "
              >{{ bookedBy }}</a
            >
          </router-link>
          <span class="text-muted font-weight-bold d-block"
            >ZNF-{{ reservationNo }}</span
          >
        </div>
      </div>
    </td>
    <td>
      <span class="text-muted font-weight-bolder">{{ phone }}</span>
    </td>
    <td>
      <span class="text-muted font-weight-bold d-block">{{
        participantsText
      }}</span>
    </td>
    <td>
      <span class="text-success font-weight-bold d-block">{{ price }}</span>
    </td>
    <td class="text-right">
      <router-link
        :to="{
          name: 'reservation-details',
          params: { id: reservationId, reservationNumber: reservationNo }
        }"
        v-slot="{ href }"
      >
        <a :href="href" class="btn btn-icon btn-light btn-sm">
          <span class="svg-icon svg-icon-md svg-icon-danger">
            <inline-svg src="media/svg/icons/Navigation/Arrow-right.svg" />
          </span>
        </a>
      </router-link>
    </td>
  </tr>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    reservationId: Number,
    bookedBy: String,
    reservationNo: String,
    phone: String,
    participants: Array,
    price: String,
    savedParticipantCount: Number
  },
  computed: {
    ...mapGetters(["experienceConfig"]),
    totalParticipant() {
      return this.participants.reduce(function(acc, cur) {
        return acc + cur.count;
      }, 0);
    },
    participantsText() {
      let text = [];
      this.participants.forEach(el => {
        text.push(
          el.count + " " + this.experienceConfig(`participant.${el.id}.tr`)
        );
      });
      return text.join(", ");
    },
    missingParticipantCount() {
      return this.totalParticipant - this.savedParticipantCount;
    }
  }
};
</script>

<style></style>
