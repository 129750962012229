<template>
  <tr>
    <td>{{ number }}</td>
    <td>{{ source }}</td>
    <td>{{ `${name} ${surname}` }}</td>
    <td>{{ genderText }}</td>
    <td>{{ passportNo }}</td>
    <td>{{ nationality }}</td>
    <td>{{ phone }}</td>
    <td>{{ pickupTime ? pickupTime.slice(0, -3) : "" }}</td>
    <td>{{ pickupPoint }}</td>
    <td>
      <span
        class="label label-inline label-light-success font-weight-bold"
        v-if="payment === 1"
      >
        Alındı
      </span>
      <span
        class="label label-inline label-light-warning font-weight-bold"
        v-if="payment === 2"
      >
        Araçta
      </span>
      <span
        class="label label-inline label-light-info font-weight-bold"
        v-if="payment === 3"
      >
        Acenteye Yaptı
      </span>
    </td>
    <td>
      <button class="btn btn-icon btn-xs btn-light mr-2" @click="copyClicked">
        <i class="flaticon2-copy"></i>
      </button>
      <button class="btn btn-icon btn-xs btn-light mr-2" @click="editClicked">
        <i class="flaticon2-edit"></i>
      </button>
      <button
        class="btn btn-icon btn-xs btn-light-danger"
        @click="removeClicked"
      >
        <i class="flaticon2-trash"></i>
      </button>
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    id: Number,
    number: Number,
    source: String,
    name: String,
    surname: String,
    gender: String,
    passportNo: String,
    nationality: String,
    phone: String,
    pickupTime: String,
    pickupPoint: String,
    payment: Number,
  },
  computed: {
    genderText() {
      if (this.gender === "M") {
        return "Erkek";
      } else if (this.gender === "F") {
        return "Kadın";
      } else {
        return "-";
      }
    },
  },
  methods: {
    editClicked() {
      this.$emit("edit", this.id);
    },
    removeClicked() {
      this.$emit("remove", this.id);
    },
    copyClicked() {
      this.$emit("copy", this.id);
    },
  },
};
</script>
