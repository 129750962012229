<template>
  <div
    class="d-flex align-items-center my-5 borderless rounded px-5 py-3"
    :class="bgColor"
    @click="onClick"
  >
    <div class="d-flex flex-column flex-grow-1 mr-2">
      <a
        href="javascript:;"
        class="font-weight-bold text-dark-75 font-size-md mb-1 d-block"
        >{{ name }}</a
      >
      <span class="text-muted font-weight-bold" v-if="description">{{ description }}</span>
    </div>
    <span class="font-weight-bolder py-1 font-size-md" :class="amountTextColor"
      >{{ sign }} {{ amount }} TRY</span
    >
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: Number,
      required: true
    },
    name: {
      type: String,
      default: ""
    },
    amount: {
      type: Number,
      default: 0
    },
    description: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      required: true,
      validator: value => {
        return ["EXPENSE", "INCOME"].indexOf(value) !== -1;
      }
    }
  },
  computed: {
    amountTextColor() {
      if (this.type === "EXPENSE") {
        return "text-danger";
      } else if (this.type === "INCOME") {
        return "text-success";
      } else {
        return "";
      }
    },
    bgColor() {
      if (this.type === "EXPENSE") {
        return "bg-light-danger";
      } else if (this.type === "INCOME") {
        return "bg-light-success";
      } else {
        return "";
      }
    },
    sign() {
      if (this.type === "INCOME") {
        return "+";
      } else {
        return "-";
      }
    }
  },
  methods: {
    onClick() {
      this.$emit("itemClick", this.id);
    }
  }
};
</script>
